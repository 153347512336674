$(document).ready(function(){

    $("#linkId").click(function(){

    	document.getElementById('linkActionButtonsId').style.visibility = 'visible'; 

    });
    
    $(window).on('scroll', function() {
        $('.anchor').each(function() {
            if($(window).scrollTop() >= $(this).offset().top-200) {
                var id = $(this).attr('id');
                $('.sidenav a').removeClass('active');
                $('#anc'+ id).addClass('active');
            }
        });
    });

    $('div.sidenav-content table tr td div.btn-group').click(function(e) {
        $(e.currentTarget).find('label > input[type="radio"]').attr('checked', false);
        $(e.target).find('input[type="radio"]').attr('checked', true);
    });    
    
    $("#resetForm").click(function(){

    	//reset pass/fail buttons
    	var fails = document.getElementsByClassName("btn btn-fail active");
    	while(fails.length>0){
	    	console.log(fails.length);
	    	for(var i = 0; i < fails.length; i++)
	        {
	    		console.log(i+': '+fails[i]);
	    		fails[i].className='btn btn-fail';
	    	}
    	}
    	
    	var fails = document.getElementsByClassName("btn btn-pass active");
    	while(fails.length>0){
	    	console.log(fails.length);
	    	for(var i = 0; i < fails.length; i++)
	        {
	    		console.log(i+': '+fails[i]);
	    		fails[i].className='btn btn-pass';
	    	}
    	}
    	
    	 //reset radio buttons and textfields
         for(var i = 0; i < 9999; i++)
         {
            var index = i;
            
            $('#userTestList_'+index+'_testFailed').attr('checked',false);
            $('#userTestList_'+index+'_testPassed').attr('checked',false);
            
            $('#userTestList_'+index+'_resultdetails').val('');
         }
        
    	return false;
    });
   
    
    $("#sentTestEmailId").click(function(){

    	console.log("sending test mail");
    	
        var csrfToken = document.getElementsByName('csrfToken')[0].value; 
        var inputString = $('#victimEmail').val();
        
        if(!validateEmail(inputString)){
        	alert('email not valid');
        	return false;
        }

    	   
 	    //send mail
	 	   $.ajax({
	           url:'/sendTestMail',
	           method: 'GET',
	           headers: {
	               'Csrf-Token': csrfToken
	            },
	           data: { email: inputString, emailBody: 'this is a test' },
	           success:function(data){
	        	   
	        	   console.log('response: '+data);
	        	   alert('Test mail sent: '+data)
	        	   
	        	   return false;

	           }
	       });
	 	   
	 	   return false; //# for IE and FF

    });
    
    $("#secondStepId").click(function(){

    	document.getElementById('linkActionButtonsIdSecondStep').style.visibility = 'visible'; 
    	
    	var elem = document.getElementById('linkActionButtonsId'); 
    	if(elem!=null){
    		document.getElementById('secondStepId').className = 'btn btn-primary btn-block disabled';
    		document.getElementById('downloadBlockedId').remove();
    		
    	}
    	
        var elem = document.getElementById('mailActionButtonsId'); 
        if(elem!=null){
    		document.getElementById('secondStepId').className = 'btn btn-primary btn-block disabled';
    		document.getElementById('blockedId').remove();
    		document.getElementById('notRecievedId').remove();

    	}

    });
    
    
    
    $('[id^="sendMailButton_"]').click(function() {
    	 
    	var clickedBtnID = $(this).attr('id'); // 
    	idx = clickedBtnID.replace('sendMailButton_userTestList_','');
    	
    	var testId = document.getElementById('userTestList_'+idx+'_test_id').value;
        var csrfToken = document.getElementsByName('csrfToken')[0].value; 
        var inputString = $('#victimEmail').val();
        
        if(!validateEmail(inputString)){
        	alert('email not valid');
        	return false;
        }

    	
    	//delete notif and disable button
    	document.getElementById('sendMailButton_userTestList_'+idx).disabled=true;
 	    document.getElementById('userTestList_'+idx+'_emailSent').style.visibility = 'hidden';
 	    
 	    //send mail
	 	   $.ajax({
	           url:'/sendMails',
	           method: 'GET',
	           headers: {
	               'Csrf-Token': csrfToken
	            },
	           data: { email: inputString, testId: testId, indexId:  idx},
	           success:function(data){
	        	   
	        	   console.log('response: '+data.split(';')[0]);
	        	   
	        	   if(data.split(';')[1]=="false"){
	        		   $('#userTestList_'+data.split(';')[0]+'_emailSent').addClass('alert-danger').html('<strong>error:</strong> '+data.split(';')[2]);
	        		   document.getElementById('userTestList_'+data.split(';')[0]+'_emailSent').style.visibility = 'visible';
		        	   document.getElementById('sendMailButton_userTestList_'+data.split(';')[0]).disabled=false;
	        	   }else{
		        	   document.getElementById('userTestList_'+data.split(';')[0]+'_emailSent').style.visibility = 'visible';
		        	   document.getElementById('sendMailButton_userTestList_'+data.split(';')[0]).disabled=false;
		           }
	        	   
	        	   return false;

	           }
	       });
	 	   
	 	   return false; //# for IE and FF

    });


    
    $('#sendAllEmailsId').click(function(evt) {
    	
    	$('#submit').prop('disabled', true);
    	
        var inputString = $('#victimEmail').val();
        
        if(!validateEmail(inputString)){
        	alert('email not valid');
        	return false;
        }
        
        var csrfToken = document.getElementsByName('csrfToken')[0].value; 
        var testIds = document.getElementsByClassName("testIds");
        console.log('total tests: '+testIds.length);
        
        //sort array #18
        items = Array.prototype.slice.call(testIds);
        items.sort(function(a, b){
	         return a.defaultValue.localeCompare(b.defaultValue);
	     });
        
	     testIds=items;

        //set init state of progress bar
        updateProgressBar(0,testIds.length);
        
        var emailCounter=0;
        for(var i = 0; i < testIds.length; i++)
        {
           console.log("sending id: "+testIds[i].value)      
           var indexEmailSent = testIds[i].id.split('_')[1];
           //console.log("indexEmailSent: "+indexEmailSent);
    	   document.getElementById('sendMailButton_userTestList_'+indexEmailSent).disabled=true;
    	   document.getElementById('userTestList_'+indexEmailSent+'_emailSent').style.visibility = 'hidden';

           $.ajax({
               url:'/sendMails',
               method: 'GET',
               headers: {
                   'Csrf-Token': csrfToken
                },
               data: { email: inputString, testId: testIds[i].value, indexId:  indexEmailSent},
               success:function(data){
            	   
            	   emailCounter++;
            	   console.log('response: '+data);
            	   	        	   
	        	   if(data.split(';')[1]=="false"){
	        		   $('#userTestList_'+data.split(';')[0]+'_emailSent').addClass('alert-danger').html('<strong>error:</strong> '+data.split(';')[2]);
	        		   document.getElementById('userTestList_'+data.split(';')[0]+'_emailSent').style.visibility = 'visible';
		        	   document.getElementById('sendMailButton_userTestList_'+data.split(';')[0]).disabled=false;
	        	   }else{
		        	   document.getElementById('userTestList_'+data.split(';')[0]+'_emailSent').style.visibility = 'visible';
		        	   document.getElementById('sendMailButton_userTestList_'+data.split(';')[0]).disabled=false;
		           }
            	 
                   updateProgressBar(emailCounter,testIds.length);
                   
                   //all sent?
                   if(emailCounter == testIds.length){
               		$('#submit').prop('disabled', false);
            		document.getElementById('progressBarId').className = 'progress-bar progress-bar-success';
               		
               	  }

               }
           });
          
        }
        
  });

    
  function updateProgressBar(actualState, totalState){
	    
      document.getElementById("progressBarId").innerHTML = actualState+" of "+totalState+" email sent";
      document.getElementById("progressBarId").style.width=(actualState*100)/totalState+"%";

  }
  
  function validateEmail(email) {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email);
	}
    
});

define("main", function(){});

